.button {
	display: inline-block;
	padding: 13px 21px;
	cursor: pointer;
	transition: all 0.4s 0s ease;

	&-buy {
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 1px;
		line-height: 21px;
		display: block;
		width: 100%;
		border: 1px solid $clr_white;
		position: relative;

		svg {
			position: absolute;
			right: 14px;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			background-color: $clr_white;
			color: $clr_primary;
		}
	}

	&-back {
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 1px;
		line-height: 21px;
		display: block;
		text-align: right;
		width: 100%;
		border: 1px solid $clr_primary;
		position: relative;

		svg {
			position: absolute;
			left: 14px;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			background-color: $clr_primary;
			color: $clr_white;
		}
	}
}
