.MyLynx__loading {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	position: relative;
	background-color: $clr_primary;
	color: $clr_secondary;
	z-index: 2000;

	.loading {
		&__container {
			text-align: center;
		}
	}
}
