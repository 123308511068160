@font-face {
	font-family: 'Beatrice';
	src: url('../../fonts/Beatrice-Regular.woff2') format('woff2'), url('../../fonts/Beatrice-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Beatrice';
	src: url('../../fonts/Beatrice-Medium.woff2') format('woff2'), url('../../fonts/Beatrice-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Termina';
	src: url('../../fonts/Termina-Demi.woff2') format('woff2'), url('../../fonts/Termina-Demi.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

body {
	font-family: 'Beatrice', sans-serif;
	font-weight: normal;
}

.teaser {
	font-family: 'Termina', sans-serif;
	font-weight: 600;
	font-size: 10px;
	line-height: 10px;
	margin-bottom: 2rem;
	letter-spacing: 2px;
	text-transform: uppercase;
}
