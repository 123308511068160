.MyLynx__preview {
	position: relative;
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;

	@include for-size(desktop-up) {
		width: calc(100% - 400px);
	}

	.preview {
		&__front,
		&__back {
			flex: 0 0 50%;
			max-width: 100px;
			height: 100%;
			position: relative;
			@include for-size(tablet-portrait-up) {
				max-width: 200px;
			}
			.teaser {
				position: absolute;

				z-index: 100;
				top: 50%;
				color: $clr_primary;
				margin-bottom: 0;
				writing-mode: vertical-lr;
			}

			.preview-image {
				top: 50%;
				left: 50%;
				width: 100%;
				height: auto;
				max-width: 70px;

				@include for-size(tablet-portrait-up) {
					width: 100%;
					max-width: 100px;
				}
			}
		}

		&__front {
			@include for-size(tablet-portrait-up) {
				margin-right: 10vw;
			}
			.teaser {
				left: 0;
				transform: translateX(-300%) translateY(-50%) rotate(180deg);
			}

			.personalization {
				position: absolute;
				bottom: 65%;
				left: 50%;
				transform: translateY(0%) translateX(-50%) rotate(180deg);
				font-family: 'Termina', sans-serif;
				font-weight: 600;
				font-size: 10px;
				text-transform: uppercase;
				text-shadow: 0 0 3px transparentize(black, 0.5);
				writing-mode: vertical-rl;

				@include for-size(tablet-landscape-up) {
					font-size: 12px;
					text-shadow: 0 0 5px transparentize(black, 0.5);
					transform: translateY(0%) translateX(-50%) rotate(180deg);
				}
			}

			.preview-image {
				transform: translate(-50%, -50%) rotate(-180deg);
			}
		}

		&__back {
			.teaser {
				right: 0;
				transform: translateX(300%) translateY(-50%);
			}

			.preview-image {
				transform: translate(-50%, -50%);
			}
		}
	}

	.preview-image {
		position: absolute;
		display: grid;
		transition: 0.4s ease;
		max-height: 90%;
		width: 150%;
		max-width: 110%;
		object-fit: contain;

		@include for-size(phone-only) {
			max-width: 250%;
		}
	}
}
