$fnt-mobile: 14px;
$fnt-lh-mobile: 19px;
$fnt-desktop: 18px;
$fnt-lh-desktop: 22px;

$padding-mobile: 1.5rem;
$padding-desktop: 2rem;

.MyLynx__summary {
	.summary {
		&__content--outer {
			display: flex;
			flex-wrap: wrap;

			@include for-size(tablet-landscape-up) {
				flex-wrap: nowrap;
			}
		}
		&__preview {
			position: relative;
			flex: 0 0 100%;
			background-color: $clr_white;
			@include for-size(phone-only) {
				max-height: 65vh;
				padding: 1rem 0;
			}

			@include for-size(tablet-landscape-up) {
				flex: 0 0 60%;
			}

			.MyLynx__preview {
				max-height: 100%;
				width: 100%;

				.preview {
					&__front {
						@include for-size(desktop-up) {
							margin-right: 50px;
						}

						.personalization {
							color: $clr_black;
						}
					}
				}
			}
		}

		&__content {
			position: relative;
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
		}

		&__list {
			margin-top: 1rem;
			margin-bottom: 1rem;
			padding: $padding-mobile;
			@include for-size(tablet-landscape-up) {
				padding: 0 $padding-desktop;
			}

			&--item {
				display: flex;
				margin-bottom: 1rem;
				height: 100%;
				max-height: 56px;

				.item {
					&__content {
						flex: 1 1 100%;
						display: flex;
						margin-right: 10px;

						@include for-size(tablet-portrait-up) {
							margin-right: 10px;
							padding-right: 10px;
							border-right: 1px solid darken($clr_secondary, 10%);
						}

						.thumbnail {
							flex: 0 0 56px;
						}
					}
					&__title {
						display: flex;
						flex-direction: column;
						align-self: center;
						width: 100%;

						.cat {
							font-family: 'Termina', sans-serif;
							font-size: 12px;
							line-height: 15px;
							text-transform: uppercase;
							letter-spacing: 1px;
							margin-bottom: 8px;
						}

						.name,
						.price {
							font-size: $fnt-mobile;
							line-height: 1;
							@include for-size(tablet-landscape-up) {
								font-size: $fnt-desktop;
							}
						}

						.price {
							font-weight: 500;
						}
					}

					&__price {
						display: flex;
						align-items: baseline;
						justify-content: space-between;
					}

					&__edit {
						margin-left: auto;
						margin-right: 0;
						flex: 0 0 auto;
						height: 100%;

						.edit-button {
							background-color: darken($clr_secondary, 10%);
							&:hover {
								background-color: darken($clr_secondary, 20%);
							}
						}
					}
				}
			}
		}

		&__personalized {
			padding: ($padding-mobile * 1.5) $padding-mobile;
			background-color: darken($clr_secondary, 10%);
			@include for-size(tablet-landscape-up) {
				padding: $padding-desktop;
			}

			p {
				font-size: $fnt-mobile;
				line-height: $fnt-lh-mobile;
				@include for-size(tablet-landscape-up) {
					font-size: $fnt-desktop;
					line-height: $fnt-lh-desktop;
				}
			}

			.personalized {
				&__text {
					font-family: 'Termina', sans-serif;
					font-weight: 600;
					letter-spacing: 1px;
					font-size: 20px;
					line-height: 25px;
					text-transform: uppercase;
					margin-top: 10px;
				}
			}
		}

		&__disclaimer {
			margin-top: auto;
			margin-bottom: $padding-mobile * 1.5;
			padding: ($padding-mobile * 1.5) $padding-mobile 0;

			@include for-size(tablet-landscape-up) {
				margin-bottom: ($padding-desktop / 2);
				padding: ($padding-desktop / 2) $padding-desktop 0;
			}

			p {
				font-size: 80%;
			}
		}

		&__price {
			//margin-top: auto;
			background-color: $clr_primary;
			color: $clr_secondary;
			padding: ($padding-mobile * 1.5) $padding-mobile;
			@include for-size(tablet-landscape-up) {
				padding: $padding-desktop;
			}
		}
	}
}
