#root {
	width: 100%;
	height: 100%;
	background-color: $clr_primary;
}

.MyLynx-Configurator {
	position: relative;
	width: 100vw;
	min-height: 100vh;
	background-color: white;
}

.MyLynx__logo {
	position: absolute;
	top: 1.8rem;
	left: 2rem;
	width: 120px;
	height: 52px;
	z-index: 200;

	@include for-size(tablet-landscape-up) {
		top: 34px;
		left: 57px;
		width: 160px;
		height: 66px;
	}

	a {
		position: relative;
		width: 100%;
		height: 100%;
		cursor: pointer;

		&:hover > img {
			opacity: 0.7;
		}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: top left;
		transition: all 0.4s ease 0s;
	}
}

.MyLynx__back {
	position: absolute;
	bottom: 1.8rem;
	left: 2rem;
	width: 160px;
	z-index: 200;

	@include for-size(tablet-portrait-up) {
		left: 3rem;
		bottom: 3rem;
	}
}

.price-box {
	margin-bottom: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: baseline;

	.big {
		font-size: 25px;
		line-height: 25px;
		font-weight: 500;
	}

	.small {
		font-size: 12px;
		line-height: 25px;
		font-weight: normal;
	}
}
