/* Breakpoints */
@mixin for-size($size) {
	@if $size == phone-only {
		@media (max-width: $bp-phone-only) {
			@content;
		}
	} @else if $size == tablet-portrait-up {
		@media (min-width: $bp-tablet-portrait-up) {
			@content;
		}
	} @else if $size == tablet-landscape-up {
		@media (min-width: $bp-tablet-landscape-up) {
			@content;
		}
	} @else if $size == desktop-up {
		@media (min-width: $bp-desktop-up) {
			@content;
		}
	} @else if $size == big-desktop-up {
		@media (min-width: $bp-big-desktop-up) {
			@content;
		}
	}
}
