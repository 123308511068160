// COLOR
$clr_primary: #002e70;
$clr_secondary: #ebeae5;
$clr_white: #fff;
$clr_black: #000;

/*************************************************
Breakpoints
*************************************************/

$bp-phone-only: 599px;
$bp-tablet-portrait-up: 600px;
$bp-tablet-landscape-up: 900px;
$bp-desktop-up: 1200px;
$bp-big-desktop-up: 1800px;
