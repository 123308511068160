.i-am-root {
	@include for-size(desktop-up) {
		display: none;
	}
}

.MyLynx__selection {
	.open-sidebar {
		position: absolute;
		right: 2rem;
		top: 1.8rem;
		color: $clr_primary;

		@include for-size(tablet-landscape-up) {
			right: 3rem;
			top: 2.8rem;
		}
	}
}

.MyLynx__sidebar-outer {
	.selection {
		&__wrapper {
			background-color: $clr_secondary;
			color: $clr_primary;
			height: 100%;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			width: 100vw;
			max-width: 350px;

			@include for-size(tablet-portrait-up) {
				max-width: 400px;
			}

			@include for-size(desktop-up) {
				width: 400px;
			}

			.close-sidebar {
				position: absolute;
				right: 2rem;
				top: 1.8rem;
				color: $clr_primary;

				@include for-size(tablet-landscape-up) {
					right: 3rem;
					top: 2.8rem;
				}

				@include for-size(desktop-up) {
					display: none;
				}
			}
		}

		&__header {
			flex: 0 0 auto;
			padding: 2rem 2rem 0 2rem;
			margin-bottom: 1rem;

			@include for-size(tablet-landscape-up) {
				padding: 3rem 3rem 0 3rem;
				margin-bottom: 2rem;
			}

			h1 {
				font-size: 25px;
				line-height: 34px;
				font-weight: 500;
				margin-bottom: 2px;
			}
		}

		&__close {
			position: absolute;
			right: 2rem;
			top: 1.8rem;
			color: $clr_primary;

			@include for-size(tablet-landscape-up) {
				right: 3rem;
				top: 2.8rem;
			}
		}

		&__content--holder {
			flex: 1 1 100%;
		}

		&__content--outer {
			height: 100%;
		}

		&__content {
			height: 100%;
			display: flex;
			flex-direction: column;
		}

		&__list {
			.list-item {
				padding: 0.5rem 2rem;

				@include for-size(tablet-landscape-up) {
					padding: 0.5rem 3rem;
				}
			}
			padding: 1rem 0;
			margin-bottom: 1rem;
		}

		&__personalize {
			padding: 1.5rem 2rem;
			border-top: 1px solid darken($clr_secondary, 10%);
			border-bottom: 1px solid darken($clr_secondary, 10%);
			color: $clr_primary;

			@include for-size(tablet-landscape-up) {
				padding: 0.5rem 3rem;
			}

			svg {
				fill: $clr_primary;
			}

			form {
				margin-bottom: 1.5rem;
				input[type='text'] {
					border: 1px solid $clr_primary;
					display: block;
					width: 100%;
					padding: 13px 21px;

					&::placeholder {
						color: transparentize($clr_primary, 0.5);
						// opacity: 0.7;
					}
				}
			}
		}

		&__checkout--outer {
			margin-top: auto;
			margin-bottom: 0;
			background-color: $clr_primary;
			color: $clr_secondary;
			padding: 3rem 2rem;

			@include for-size(tablet-landscape-up) {
				padding: 3rem;
			}
		}

		&__checkout {
			.price-box {
				margin-bottom: 2rem;
				display: flex;
				justify-content: space-between;
				align-items: baseline;

				.big {
					font-size: 25px;
					line-height: 25px;
					font-weight: 500;
				}

				.small {
					font-size: 12px;
					line-height: 25px;
					font-weight: normal;
				}
			}

			button:disabled {
				opacity: 0.2;
			}
		}
	}
}
